import PricingPlans from '@shared/ui/pricing-plans'

export default function PricingSection() {
  return (
    <div className="bg-white py-10">
      <section className="max-w-[900px] mx-auto px-5">
        <div className="flex flex-col gap-3 mb-5">
          <h4 className="text-2xl md:text-4xl font-medium text-center">
            Simple, Affordable <span className="text-primary-900">Pricing</span>
          </h4>
          <p className="text-center text-current-700 text-sm">
            Choose one of our Pro Plans and enjoy the benefits that come with it
          </p>
        </div>
        <PricingPlans />
      </section>
    </div>
  )
}
