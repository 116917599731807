import { useNavigate } from 'react-router-dom'

import ROUTEMAP from '@shared/routemap.json'
import { Button } from '@shared/ui'

import BGImageRight from '@/assets/images/base/man_waving_in_emeet.svg'
import BGImageLeft from '@/assets/images/base/woman_smiling_back_in_emeet.svg'

export default function CTACard() {
  const navigate = useNavigate()
  return (
    <div
      className="mx-2 md:mx-auto max-w-[900px] min-h-80 flex flex-col justify-center items-center gap-5 rounded-md px-5 py-10 mb-5 md:mb-16 text-white text-center bg-blend-darken bg-left-top bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${BGImageLeft}), url(${BGImageRight})` }}
    >
      <div className="text-center">
        <h4 className="mb-1 text-2xl md:text-3xl">Create a CV that lands your dream job</h4>
        <p className="text-sm">
          Start building the outstanding CV that will help you make your next career move now.
        </p>
      </div>
      <Button size="md" onClick={() => navigate(ROUTEMAP.cv.BUILD)}>
        Create CV
      </Button>
    </div>
  )
}
