import { Textify } from '@shared/lib'
import { Icon } from '@shared/ui'
import clsx from 'clsx'
import AutoScroll from 'embla-carousel-auto-scroll'
import useEmblaCarousel from 'embla-carousel-react'

type TestimonialCardProps = {
  name: string
  rating: number
  review: string
  date?: string
  className?: string
}

const randomKeys = Array.from({ length: 5 }).map(() => Textify.generateRandomString(3))

const TestimonialCard = ({ name, rating, review, date, className }: TestimonialCardProps) => {
  return (
    <div
      className={clsx('bg-white rounded-md border min-w-[400px] p-5 mx-3 embla__slide', className)}
    >
      <div className="flex items-center gap-1">
        {Array.from({ length: 5 }).map((_, i) => (
          <Icon
            key={randomKeys[i]}
            icon="star"
            size="sm"
            color={i + 1 <= rating ? 'yellow-400' : 'gray-300'}
          />
        ))}
      </div>
      <div className="flex flex-col gap-2 mt-5">
        <h5 className="text-md font-medium">{name}</h5>
        <q className="text-sm">{review}</q>
        {date && <p className="text-xs mt-5 ml-auto text-current-500 w-fit">{date}</p>}
      </div>
    </div>
  )
}

export default function TestimonialsSection() {
  const [emblaRow1Ref] = useEmblaCarousel({ loop: true, active: true }, [
    AutoScroll({ direction: 'forward' }),
  ])
  const [emblaRow2Ref] = useEmblaCarousel({ loop: true, active: true }, [
    AutoScroll({ direction: 'backward' }),
  ])

  return (
    <div className="bg-white py-16">
      {/* Two rows auto scroll animated testimonials */}
      <section className="max-w-[900px] mx-auto px-5">
        <div className="flex flex-col gap-3">
          <h4 className="text-2xl md:text-4xl font-medium text-center">Testimonials</h4>
          <p className="text-center text-current-500">
            Here are what some of our users have to say about us
          </p>
        </div>
        <div className="flex flex-col gap-5 mt-5 md:mt-10">
          {/* Animate auto scroll from left to right */}
          <div className="embla">
            <div className="overflow-x-hidden embla__viewport" ref={emblaRow1Ref}>
              <div className="embla__container flex py-5">
                {Array.from({ length: 5 }).map((_, i) => (
                  <TestimonialCard
                    key={i}
                    name="John Doe"
                    rating={4}
                    review="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    date="2021-10-10 12:00 PM"
                  />
                ))}
              </div>
            </div>
          </div>
          {/* Animate auto scroll from right to left */}
          <div className="embla">
            <div className="overflow-x-hidden embla__viewport" ref={emblaRow2Ref}>
              <div className="embla__container flex py-5">
                {Array.from({ length: 5 }).map((_, i) => (
                  <TestimonialCard
                    key={i}
                    name="John Doe"
                    rating={4}
                    review="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    date="2021-10-10 12:00 PM"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
