import { sessionSlice } from '@entities/session/model'
import { API } from '@shared/lib'
import { IUser } from '@shared/model'
import { AxiosResponse } from 'axios'

import {
  ForgotPasswordDTO, // ForgotPasswordResponse,
  type LoginUserDTO,
  LoginUserResponse,
  ResetPasswordDTO,
  type SignupUserDTO,
} from './types'

const api = new API({
  baseURL: import.meta.env.VITE_REVAMPINSIGHTS_API_URL + '/auth',
  isPrivate: true,
  timeout: 80000,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default class SessionAPI {
  // Auth API
  static async hasPerm(roleId: string, perm: string): Promise<boolean> {
    const { data, status } = await api.get<{
      success: boolean
      message: string
    }>('/authorize', {
      params: { roleId, permissionName: perm },
      headers: { withCredentials: true },
    })

    if (status === 403) {
      return false
    }

    return data.success
  }

  static async signup(data: SignupUserDTO): Promise<AxiosResponse> {
    const res = await api.post('/register', data)
    if (res.status === 200) {
      window.location.pathname = `/auth/verify-email/${data.email}`
    }
    return res
  }

  static async login(data: LoginUserDTO): Promise<AxiosResponse> {
    const res = await api.post('/login', data)
    // set token from response
    sessionSlice.setToken((res.data as LoginUserResponse).accessToken)
    return res
  }

  static async forgotPassword(data: ForgotPasswordDTO): Promise<AxiosResponse> {
    const res = await api.post('/forgot_password', data)
    if (res.status === 200) {
      window.location.pathname = `/auth/check-email/${data.email}`
    }

    return res
  }

  static async resetPassword(data: ResetPasswordDTO): Promise<AxiosResponse> {
    const res = await api.put('/reset_password', data)
    if (res.status === 200) {
      window.location.pathname = `/auth/login`
    }
    return res
  }

  static async logout(): Promise<unknown> {
    const { data } = await api.delete('/auth', { headers: { withCredentials: true } })

    // clear token
    api.token.value = null
    return data
  }

  // User API
  static async get(token: string): Promise<Omit<IUser, 'cv'>> {
    // set token
    api.token.value = token

    // fetch user data
    const { data } = await api.post('/validate', null, { headers: { withCredentials: true } })
    return data as Omit<IUser, 'cv'>
  }
}
