import { sessionSlice } from '@entities/session/model'
import { useStep } from '@shared/hooks'
import { API } from '@shared/lib'
import { IUserCV, UserCV } from '@shared/model/usercv'
import { DetailedStepsHorizontal } from '@shared/ui'
import { WithMultiStepsLoader } from '@widgets/loaders'
import DefaultCVScreenshot from '@widgets/templates/assets/default.svg'
import clsx from 'clsx'

import { steps } from './model'

export default function FAssistedBuildMode() {
  const { stepId, step, stepBack, stepNext } = useStep(steps)

  const navigate = (back: boolean) => {
    back ? stepBack() : stepNext()
  }

  const createCV = async (data: Record<string, unknown>): Promise<void> => {
    // send the data to the server
    const api = new API({
      baseURL: import.meta.env.VITE_RI_RESUME_API_URL,
      timeout: 3000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    const res = await api.post('/user/new', data)

    const cv = UserCV.create(res.data as IUserCV)
    sessionSlice.updateUser('cv', cv)
  }

  return (
    <div className="flex flex-col gap-5 my-5">
      {/* Detailed Steps UI */}
      <DetailedStepsHorizontal
        steps={steps}
        currentStep={stepId}
        interactive
        horizontal
        className="w-full mb-5"
        stepComponent={({ title, is_active }) => (
          <p
            className={clsx('text-[#0959FE4D] text-[6px] md:text-xs', {
              'font-semibold text-[#0959FE]': is_active,
            })}
          >
            {title}
          </p>
        )}
      />
      <div>
        <h4 className="text-lg font-semibold">{step.title}</h4>
        <p className="text-sm text-gray-500 w-fit md:w-[calc(100vw-300px)]">{step.description}</p>
      </div>
      <WithMultiStepsLoader
        stepId={stepId}
        currentStep={step}
        navigate={navigate}
        onComplete={createCV}
        isLastStep={stepId >= steps.length}
      >
        {stepId < steps.length - 1 && (
          <img
            src={DefaultCVScreenshot}
            alt="Default CV Screenshot"
            className="w-[18.75rem] h-[18.75rem] hidden sm:inline-flex"
          />
        )}
      </WithMultiStepsLoader>
    </div>
  )
}
