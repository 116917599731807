import { RouteObject } from 'react-router-dom'

import { AppLayout } from '@shared/ui'

import AboutUs from './about-us'
import CTACard from './common/cta-card'
import ContactUs from './contact-us'
import AppLandingPage from './landing-page'

export default {
  path: '/',
  element: (
    <AppLayout>
      <AppLandingPage />
      <CTACard />
    </AppLayout>
  ),
  children: [
    {
      path: 'about',
      element: <AboutUs />,
    },
    {
      path: 'contact',
      element: <ContactUs />,
    },
  ],
} as RouteObject
