import ROUTEMAP from '@shared/routemap.json'
import { AppLogo, Button, Icon, InputBox } from '@shared/ui'

const Footer: React.FC = () => {
  const socialLinks = ['linkedin-in', 'twitter', 'facebook-f', 'instagram']

  return (
    <footer className="flex flex-col bg-[#051A45] text-white py-5 gap-6 px-6">
      <section className="flex flex-col md:flex-row text-center md:text-start items-center md:items-start md:justify-between gap-6 md:gap-4 w-[92%] mx-auto my-7 flex-wrap">
        <AppLogo className="text-white text-xl pb-2" colored={false} />
        <div className="flex flex-col md:flex-row items-center md:items-start justify-between gap-12 flex-wrap">
          <div className="mr-8">
            <h4 className="text-2xl">Company</h4>
            <ul className="text-[#E9F7FE] font-light">
              <li className="leading-8">
                <a href={ROUTEMAP.base.BLOG}>Blog</a>
              </li>
              <li className="leading-8">
                <a href={ROUTEMAP.base.ABOUT}>About Us</a>
              </li>
              <li className="leading-8">
                <a href={ROUTEMAP.base.CONTACT}>Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-3">
            <h4 className="text-2xl">Newsletter</h4>
            <p className="text-[#8C97AC]">
              Subscribe to our newsletter to receive weekly
              <br />
              updates about the best resume guide
            </p>
            <form className="flex mx-auto md:mx-px">
              <div className="flex px-2 rounded-l-md items-center bg-white text-current-500">
                <Icon
                  icon="envelope"
                  className="text-md"
                  color="current-900"
                  animation="animate-bounce"
                />
                <InputBox
                  showLabel={false}
                  type="email"
                  placeholder="Enter your email"
                  css={{
                    input:
                      'border-none rounded-none outline-none shadow-none text-current-900 focus:ring-0',
                  }}
                />
              </div>
              <Button className="rounded-l-none">Subscribe</Button>
            </form>
          </div>
        </div>
      </section>
      <div className="flex flex-col md:flex-row gap-4 md:gap-2 text-center text-sm items-center justify-between w-[92%] mx-auto py-3 border-[#313D55] border-t border-solid">
        <span className="text-[#8C97AC]">
          © Revampinsights. All Right Reserved | support@revampinsights.com
        </span>
        <div className="flex items-center gap-2">
          {socialLinks.map((sl) => (
            <span
              className="text-white bg-[#d9d9d949] flex items-center justify-center w-8 h-8 p-4 rounded-full border border-solid border-[#FCFCFC]"
              key={`social-link-${sl}`}
            >
              <Icon icon={sl} style="brands" />
            </span>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default Footer
